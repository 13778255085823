// import { print } from "util";
import "slick-carousel";
import "slick-lightbox";
import tilt from './modules/tilt-jquery';
import navbar from "./modules/navbar";
import { effectivePredictor } from "./modules/effective-predictor";
import newInsights from "./modules/newInsights";
import dataReports from "./modules/data-reports";
import newPub from "./modules/newPub";
import loader from "./modules/loader";
import pubHubSearch from "./modules/pubSearch";
import insightHubSearch from "./modules/insightSearch"; // effectiveness
import insightHubSearchCatI from "./modules/insightSearchCatInsight"; // insights/insights/onepagers search
import insightsHome from "./modules/insightsHome";
import regionalMap from "./modules/regionMap";
import coverMaps from "./modules/coverageMaps";
import packagegrabSheet from "./modules/package-grabsheet";
import landingPage from "./modules/landingPage";
import register from "./modules/register";
import Rellax from "./modules/rellax.min";
import training from "./modules/training";
import nickableSlide from "./modules/nickable-slides";
import ourVideo from "./modules/ourVideo";
import publishingpage from "./modules/publishing";
// import inyourareaSearch from "./modules/inyourareaListing";
import contentLayouts from "./modules/contentLayouts";
import contentVideo from "./modules/addNewVideo";
import contentHubHome from "./modules/contentHome";
import eventsCats from "../../../models/eventsCats";
import IYAPosts from "./modules/IYAPosts";
import FBPosts from "./modules/FBPosts";


if (document.getElementsByTagName("body")[0]) {
  loader.init();
   
}

if (document.getElementById("landingpage")) {
  landingPage.init();
}

if (document.getElementById("landingPub")) {
  landingPage.categoriesFlip();
}


if (document.getElementById("creativestorehome") 
|| document.getElementById("videostorehome")
|| document.getElementById("eventsstorehome")
|| document.getElementById("inyourareahubhome")
|| document.getElementById("facebookhubhome")
|| document.getElementById("marketing-index")) {
  landingPage.init();
  landingPage.categoriesFlip();

}




if (document.querySelector("#packagesgrabsheet")) {
  regionalMap.map();
}

if (document.getElementById("training")) {
  training.init();
}

if (document.getElementById("nickableSlides")) {
  nickableSlide.init();
}

if (document.getElementById("our-videos")) {
  ourVideo.init();
}

if (document.getElementById("publishingpage")) {
  publishingpage.init();
}

if (document.getElementById("iya-posts")) {
  IYAPosts.init();
}

if (document.getElementById("fb-posts")) {
  FBPosts.init();
}

if (document.getElementById("contentList")) {
  contentLayouts();
}

if (typeof userLogin !== "undefined") {
  // Logged in only
  navbar.init();
} else if (document.getElementById("registerPage")) {
  register.init();
}

// get event categories and make sure it is an array
const eventsCatsArr = Object.values(eventsCats)[0];
// Carousel
$(document).ready(function () {


  let closeModal = true;
  // Admin Script - enable form to change Print Proof PDF
  if (document.getElementById("productindex")) {
    const page = document.querySelector(".creative");

    function printModalHandler(e) {
      e.preventDefault();
      page.innerHTML += `
				<div class='printproofwindow' id="printproofwindow">
					<div id='printproofwindow' class='modal marketingsub' style="opacity: 1; visibility: visible;">
						<div class="inner">
							<div class="modal-content">
								<h1>${e.target.dataset.urn}<span class="white">.</span></h1>
                <div class='sublinks' id='sublinks'>
									<a href="${e.target.dataset.proof}" target="_blank">Current Print Proof Link</a>
									<a href="${e.target.dataset.digital}" target="_blank">Current Digital Proof Link</a>
								</div>
								<form action='/products/printproof/${e.target.dataset.id}' method='POST' enctype="multipart/form-data" class="form" id='printproofreplace'>
									<input type='hidden' name='hub' value='creativestore'>
									<input type='hidden' name="section" value='${e.target.dataset.cat}'>
									<input type='hidden' name="category" value='${e.target.dataset.urn}'>
									<input type='hidden' name="name" value='replacedproof'>
                  <div class="form__group">
                    <label class="form__label">Print Proof</label>
										<input required class="form__input" type='file' accept="application/pdf" name='printproofupload'/>
                    <label class="form__label">Digital Proof</label>
										<input required class="form__input" type='file' accept="application/pdf" name='digitalproofupload'/>
									</div>
									<div class="form__group">
										<input class="form__input btn btn--trans_yel" type='submit'/>
									</div>
								</form>
								<button class='ppclose'>Close</button>
							</div>
						</div>
					</div>
				</div>
			`;

      $(".ppclose").on("click", () => {
        document.location.reload();
      });
    }

    $(".printproofchange").on("click", (e) => {
      printModalHandler(e);
    });
  }

  // product tilt

  $('.products__link').tilt({
    maxTilt: 20,
    perspective: 1000,
    easing: 'cubic-bezier(.03,.98,.52,.99)',
    speed: 1200,
    glare: true,
    maxGlare: 0.2,
  });

  // Sub category scroll
  const secondaryNavList = [].slice.call(document.querySelectorAll(".cat-header__list .cat-header__item"));
  const catTarget = document.querySelector(".cat-header__list .current");
  const needle = secondaryNavList.indexOf(catTarget);
  let catSettings;

  // Stop slickjs from breaking insight hub categories navigation layout
  if (
    document.getElementById("insightdatahubdigitalpage") ||
    document.getElementById("insightdatahubmultiplatformpage") ||
    document.getElementById("insightdatahubprintpage") ||
    document.getElementById("insightdatahubreportpage") ||
    document.getElementById("onepagersregions")
  ) {
    catSettings = {
      infinite: false,
      centerPadding: "0",
      slidesToShow: 4,
      swipeToSlide: true,
      variableWidth: false,
      rows: 0,
    };
  } else if (
    document.getElementById("theinsighthub") ||
    document.getElementById("theinsighthubcat") ||
    document.getElementById("onepager") ||
    document.getElementById("thoughtleadership") ||
    document.getElementById("packagesgrabsheet") ||
    document.getElementById("predictor") ||
    document.getElementById("regionalgrabsheets") ||
    document.getElementById("publicationpage") ||
    document.getElementById("coveragepage") ||
    document.getElementById("circulationpage") ||
    document.getElementById("publishingpage")
  ) {
    catSettings = {
      slidesToShow: 5,
      swipeToSlide: false,
      variableWidth: false,
      rows: 0,
    };
  } else {
    catSettings = {
      infinite: true,
      centerMode: true,
      centerPadding: "0",
      slidesToShow: 8,
      swipeToSlide: true,
      variableWidth: true,
      initialSlide: needle,
      rows: 0,
      prevArrow: '<img class="slick-prev" src="/assets/reach/img/arr-l.svg"></img>',
      nextArrow: '<img class="slick-next" src="/assets/reach/img/arr-r.svg"></img>',
    };
  }

  // Init slick carousel
  $(".cat-header__list").slick(catSettings);

  // Close flash message
  $(".flash-close").on("click", function () {
    this.parentNode.parentNode.remove();
  });

  // Fix navigation only for logged in users
  if (typeof userLogin !== "undefined") {
    $(window).scroll(function () {
      const sc = $(window).scrollTop();

      if (sc > 40) {
        $("body").addClass("fixed");
      } else {
        $("body").removeClass("fixed");
      }

      if (!document.getElementById("landingpage")) {
        if (sc > 271) {
          $(".cat-header").addClass("fixed");
        } else {
          $(".cat-header").removeClass("fixed");
        }
      }
    });
  }

  // Single page
  if (typeof showmodal !== "undefined") {
    if (isVideo) {
      console.log("VIDEO")
      grabVideo(event);
    } else {
      console.log("PRODUCT")
      grabProduct(event);
    }

    setTimeout(function () {
      const modal = document.querySelector(".modal");
      // var backButton = document.querySelector(".back-button");
      const closeButton = document.querySelector(".close-button");

      function toggleModal() {
        modal.classList.toggle("show-modal");
      }

      function modalDestroy() {
        toggleModal();
        setTimeout(function () {
          modal.remove();
        }, 300);
      }

      function windowOnClick(event) {
        console.log("modal");
        if (event.target === modal) {
          modalDestroy();
        }
      }

      // Trigger modal after content is loaded
      toggleModal();
      closeButton.addEventListener("click", modalDestroy);
      // backButton.addEventListener("click", modalDestroy);
      window.addEventListener("click", windowOnClick);
      // backButton.addEventListener("click", modalDestroy);

      // Share  link
      shareLink();
    }, 200);
  }

  // Category page
  let productButton = document.querySelectorAll(".products__link");

  // if catgory is an event category then target the first product
  // if (storeProducts[0].product.category === 'events-care-health' || storeProducts[0].product.category === 'events-top-100-top-200' || storeProducts[0].product.category === 'events-innovation' || storeProducts[0].product.category === 'events-community' || storeProducts[0].product.category === 'events-renewable' || storeProducts[0].product.category === 'events-women-business' || storeProducts[0].product.category === 'events-culture' || storeProducts[0].product.category === 'events-tech' || storeProducts[0].product.category === 'events-apprenticeship' || storeProducts[0].product.category === 'events-business') {

  //   productButton = Array.from(productButton).slice(0,1);
  // }

  productButton.forEach(function (event, index) {
    $(event).on("click", function (event) {
      event.preventDefault();
      if (isVideo) {
        grabVideo(event);
      } else {
        grabProduct(event);
      }
      // grabProduct(event);

      setTimeout(function () {
        const modal = document.querySelector(".modal");
        // var backButton = document.querySelector(".back-button");
        const closeButton = document.querySelector(".close-button");

        function toggleModal() {
          modal.classList.toggle("show-modal");
        }

        function modalDestroy() {
          toggleModal();
          setTimeout(function () {
            modal.remove();
          }, 300);
        }

        function windowOnClick(event) {
          if (event.target === modal) {
            modalDestroy();
          }
        }

        // Trigger modal after content is loaded
        toggleModal();
        closeButton.addEventListener("click", modalDestroy);
        // backButton.addEventListener("click", modalDestroy);
        window.addEventListener("click", windowOnClick);

        // Share  link
        shareLink();
      }, 200);
    });

// for each thumb on mouseenter load in video and play, on mouseout pause video 
// so only 1 video is loaded at a time

var thumb = [...document.getElementsByClassName("products-video__picture")];
var vid = document.createElement("video");
var videoContainer = document.getElementById(`video-container-${index}`);



if ( Array.isArray(storeProducts[index]?.video?.videoSocial)){
  // get mp4 path for social video
  var mp4Arr = storeProducts[index].video.videoSocial.filter(function(itm){
    return itm.type == "video/mp4";
  });
  // get webm path for social video
  var webmArr = storeProducts[index].video.videoSocial.filter(function(itm){
    return itm.type == "video/webm";
  });

  if (vid.canPlayType('video/webm') && webmArr.length > 0) vid.src = webmArr[0].path
  else if (vid.canPlayType('video/mp4') && mp4Arr.length > 0) vid.src = mp4Arr[0].path
}
// vid.src = storeProducts[index].video.videoSocial;
vid.controls = false;
vid.autoplay = true;
vid.muted = true;
vid.poster = storeProducts[index]?.video?.thumbnail;
vid.preload = true;
vid.width = "250";
vid.classList.add("video-preview");
if (videoContainer !== null) {
  $(event).on("mouseenter", function (event) {
  
    event.preventDefault();
  
    videoContainer.appendChild(vid)

  console.log(vid.readyState)
    if (isVideo && vid.readyState > 3 ) {
      let isPlaying =  !vid.paused && !vid.ended;
      if (!isPlaying) {
        thumb[index].style.opacity = "0";
        event.target.parentElement.style.scale = "1.05";
        vid.play()
      }
    } 
    }).on("mouseout", function (event) {
      event.preventDefault();
      let isPlaying =  !vid.paused && !vid.ended 
  
      if (isVideo && isPlaying) {
        thumb[index].style.opacity = "1";
        event.target.parentElement.style.scale = "1"
        vid.pause();
      }
    });
}

    // console.log(storeProducts)
    // console.log(isProduct)
    // If category is an event category then trigger a fake click to open the modal automatically
    // if ((closeModal == false) && (eventsCatsArr.indexOf(storeProducts[0].product.category) >= 0)) {
    // console.log(showmodal)
    // if ((closeModal === true) && (isProduct === null) && (eventsCatsArr.indexOf(storeProducts[0].product.category) >= 0)) {
    // $(event).trigger("click") //// ISSUE HERE
    // closeModal = false;
    // showmodal= false;
    // }
  });
console.log(process.env.NODE_ENV)
  var images = document.getElementsByClassName("fbdl");
  var imagesArr = Array.prototype.slice.call(images);

  if (imagesArr.length > 0) {
    imagesArr.forEach((image, index) => {
      const id = image.getAttribute("data-id");
      const cat = image.getAttribute("data-cat");

      // on each click, image will be added to database along with the defualt action of downloading the image
      image.addEventListener("click", async (e) => {
        try {
          await axios({
            method: "POST",
            url: `/facebook/${cat}/${id}/reserve`,
          });
          // window.setTimeout(() => {
          //   location.reload();
          //   return false;
          // }, 5000);
        } catch (err) {
          showAlert("error", err.response.data.message);
        } 
        // finally {
        //   window.setTimeout(() => {
        //     document.location.reload();
        //     // location.reload();
        //     return false;
        //   }, 1000);
        // }
      });
    });
  }

  /////////////////////////////////////
  // PRODUCT
  // Load products for modal
  /////////////////////////////////////

  function grabProduct(event) {
    let htmlBody = document.querySelector(".modal");
    let showForm;
    let sharebBackButton;
    let region; // Stores region
    const categoryProduct = []; // Stores all products sorted By URN
    let htmlsource; // Stores the modal container
    let productIndex; // Retrieve a specific product from provided array, found in index.pug
    let productLink; // Store the individual product

    if (typeof showmodal === "undefined") {
      productLink = event.currentTarget.href.split("/").pop();
    } else if (typeof showmodal !== "undefined" && isProduct === null && eventsCatsArr.indexOf(storeProducts[0].product.category) >= 0) {
      productLink = storeProducts[0].product.urn;
      showmodal = undefined;
    } else if (typeof showmodal !== "undefined" && isProduct === true && eventsCatsArr.indexOf(storeProducts[0].product.category) >= 0) {
      productLink = event.currentTarget.href.split("/").pop();
      showmodal = undefined;
    } else if (typeof showmodal === "undefined" && isProduct === true && eventsCatsArr.indexOf(storeProducts[0].product.category) >= 0) {
      productLink = event.currentTarget.href.split("/").pop();
      showmodal = undefined;
    } else {
      productLink = window.location.href.split("/").pop();
      showmodal = undefined;
    }

    storeProducts.forEach(function (item) {
      categoryProduct[item.product.urn] = item;
      // categoryProduct[item.video.urn] = item;
    });

    productIndex = categoryProduct[productLink].product;
    // productIndex = categoryProduct[productLink].video;
    region = categoryProduct[productLink]._id;

    htmlBody = document.querySelector("body");
    console.log(categoryProduct)

    if (typeof userLogin !== "undefined" && eventsCatsArr.indexOf(productIndex.category) >= 0) {
      showForm = `<form class="modal-add-to-cart" action="/account/add-to-basket" method="post">
			<input type="hidden" name="resRegion" value="${region}">
			<input type="hidden" name="resProduct" value="${productIndex._id}">
			</form>`;

      sharebBackButton = `/products/${productIndex.category}`;
    } else if (typeof userLogin !== "undefined") {
      showForm = `<form class="modal-add-to-cart" action="/account/add-to-basket" method="post">
			<input type="hidden" name="resRegion" value="${region}">
			<input type="hidden" name="resProduct" value="${productIndex._id}">
			<button class="btn" type="submit" value="add">Reserve</button>
			</form>`;

      sharebBackButton = `/products/${productIndex.category}`;
    } else {
      showForm = "";
      sharebBackButton = `/products/share/${categoryProduct[productLink].name}/${productIndex.category}`;
    }

    // Check if product uses Google Cloud Storage or S3

    let cloudStorage = null;
    const prodArr = productIndex.image.split("/");
    const imageFileName = prodArr[2];


    if (imageFileName == "storage.googleapis.com") {
      cloudStorage = "GCS";
    } else if (imageFileName == "creative-store.s3.eu-west-2.amazonaws.com") {
      cloudStorage = "S3"
    }

    if (eventsCatsArr.indexOf(productIndex.category) >= 0) {
      htmlsource = `
      <div class="modal">
        <div class="modal-content">
          <div class="inner">
            <div class='thumbs'>
              <div class="wrap">
                <div class="product-thubnail">
                    <div class="thumbnail-inner" id="prodthumbs">
                      <div class="thumb-picture"><img src="${cloudStorage === "S3" ? productIndex.thumbnail : cloudStorage === "GCS" ? productIndex.image : null}" alt=""></div>
                    </div>
                </div>
              </div>
            </div>
            <div class="bigimage">
              <div class="product-gallery" id="prodgal">
                <div data-title="${productIndex.urn}_a5 invite.jpg"><img src="${productIndex.image}" alt="Image"></div>
              </div>
              <div class="imgtitle" id='imgtitle'></div>
            </div>
            <div class="content">
              <div class="content__header">
                <a href="javascript:;"><span class="close-button">Close</span></a>
              </div>
              <div class="content__body">
                <div class="details">
                  <p>TRN: <span class="meta">${productIndex.urn}</span></p>
                  ${showForm}
                </div>
                <div class="copytext">
                  <div class="confirmation"><span class="icon-checkmark"></span> Copied to clipboard</div>
                  <div class="share-box">https://${link}${productIndex.category}/${productIndex.urn}</div>
                </div>
              </div>
              <div class="content__footer">
                <div class="btn btn--trans_yel share-button">Share Template <span class='shareico'></span></div>
              </div>
  
            </div>
          </div>
        </div>
      </div>`;
    } else {
      htmlsource = `
      <div class="modal">
        <div class="modal-content">
          <div class="inner">
            <div class='thumbs'>
              <div class="wrap">
                <div class="product-thubnail">
                    <div class="thumbnail-inner" id="prodthumbs">
                      <div class="thumb-picture"><img src="${cloudStorage === "S3" ? productIndex.thumbnail : cloudStorage === "GCS" ? productIndex.image : null}" alt=""></div>
                    </div>
                </div>
              </div>
            </div>
            <div class="bigimage">
              <div class="product-gallery" id="prodgal">
                <div data-title="${productIndex.urn}_fullpage.jpg"><img src="${productIndex.image}" alt="Image"></div>
              </div>
              <div class="imgtitle" id='imgtitle'></div>
            </div>
            <div class="content">
              <div class="content__header">
                <a href="${sharebBackButton}"><span class="close-button">Close</span></a>
              </div>
              <div class="content__body">
                <div class="details">
                  <p>TRN: <span class="meta">${productIndex.urn}</span></p>
                  ${showForm}
                </div>
                <div class="copytext">
                  <div class="confirmation"><span class="icon-checkmark"></span> Copied to clipboard</div>
                  <div class="share-box">https://${link}${productIndex.category}/${productIndex.urn}</div>
                </div>
              </div>
              <div class="content__footer">
                <div class="btn btn--trans_yel share-button">Share Template <span class='shareico'></span></div>
              </div>

            </div>
          </div>
        </div>
      </div>`;
    }

    htmlBody.insertAdjacentHTML("beforeend", htmlsource);
    const thumbsSec = document.getElementById("prodthumbs");
    const galSec = document.getElementById("prodgal");

    if (document.querySelector(".contentprodssingle")) {
      const modal = document.querySelector(".modal");
      if (!modal) return;
      modal.addEventListener("click", (e) => {
        if (e.target === modal) {
          modal.classList.remove("show-modal");
          setTimeout(() => {
            modal.remove();
          }, 300);
          const uri = window.location.toString();
          location.href = uri.substring(0, uri.lastIndexOf("/") + 1);
        }
      });
    }
console.log(productIndex.mockups)
    productIndex.mockups.map((thumb, index) => {
      // thumbsSec.innerHTML += `
			// 	<div class="thumb-picture"><img src="${cloudStorage === "S3" ? thumb + "-thumb" : cloudStorage === "GCS" ? thumb : null}" alt=""></div>
			// `;
      thumbsSec.innerHTML += `
      <div class="thumb-picture"><img src="${cloudStorage === "S3" ? thumb  : cloudStorage === "GCS" ? thumb : null}" alt=""></div>
    `;

      if (productIndex.mockupMeta && productIndex.mockupMeta[index] && productIndex.mockupMeta[index]) {
        if (productIndex.mockupMeta[index].name.substr(productIndex.mockupMeta[index].name.length - 8) === "17x3.jpg") {
          return "";
        }
        if (productIndex.mockupMeta[index].name.substr(productIndex.mockupMeta[index].name.length - 8) === "17x4.jpg") {
          return "";
        }
        // galSec.innerHTML += `
				// 	<div data-title="${productIndex.mockupMeta[index].name}"><img src="${cloudStorage === "S3" ? thumb + "-full" : cloudStorage === "GCS" ? thumb : null}" alt="Image"/></div>
				// `;
        galSec.innerHTML += `
        <div data-title="${productIndex.mockupMeta[index].name}"><img src="${cloudStorage === "S3" ? thumb  : cloudStorage === "GCS" ? thumb : null}" alt="Image"/></div>
      `;
      } else {
        galSec.innerHTML += `
					<div><img src="${cloudStorage === "S3" ? thumb + "-full" : cloudStorage === "GCS" ? thumb : null}" alt="Image"/></div>
				`;
      }
    });
    const imageTitle = document.getElementById("imgtitle");

    function formatTitle(title) {
      if (title) {
        return title.substring(title.lastIndexOf("_") + 1, title.lastIndexOf("."));
      }
      return "";
    }

    setTimeout(function () {
      // Product modal
      if (document.querySelector(".branded")) {
        console.log("content index");
        $(".product-gallery").not(".slick-initialized").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          mobileFirst: true,
          swipeToSlide: true,
          accessibility: true,
          centerPadding: "0",
          focusOnSelect: true,
          centerMode: true,
          rows: 0,
        });

        $(".product-gallery").slickLightbox({
          itemSelector: "img",
          navigateByKeyboard: true,
          src: "src",
        });
      } else {
        console.log("not content index");
        $(".product-gallery").not(".slick-initialized").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          mobileFirst: true,
          swipeToSlide: true,
          accessibility: true,
          centerPadding: "0",
          focusOnSelect: true,
          centerMode: true,
          rows: 0,
        });
      }
      // Thumbnail
      $(".thumbnail-inner").not(".slick-initialized").slick({
        asNavFor: ".product-gallery",
        arrows: false,
        dots: false,
        mobileFirst: true,
        slidesToShow: 15,
        centerMode: false,
        centerPadding: "10px",
        focusOnSelect: true,
        vertical: true,
      });

      const title = formatTitle($(".product-gallery .slick-active.slick-current").attr("data-title"));
      imageTitle.innerText = title;
    }, 200);

    $(".product-gallery").on("afterChange", function (event, slick, currentSlide, nextSlide) {
      const title = formatTitle($(".product-gallery .slick-active.slick-current").attr("data-title"));
      imageTitle.innerText = title;
    });
  }

  /////////////////////////////////////////////
  // VIDEO
  // Load video for modal
  /////////////////////////////////////////////

  function grabVideo(event) {
    let htmlBody = document.querySelector(".modal");
    let showForm;
    let sharebBackButton;
    let region; // Stores region
    const categoryProduct = []; // Stores all products sorted By URN
    let htmlsource; // Stores the modal container
    let productIndex; // Retrieve a specific product from provided array, found in index.pug
    let productLink; // Store the individual product

    if (typeof showmodal === "undefined") {
      productLink = event.currentTarget.href.split("/").pop();
    } else if (typeof showmodal !== "undefined" && isProduct === null && eventsCatsArr.indexOf(storeProducts[0].product.category) >= 0) {
      productLink = storeProducts[0].product.urn;
      showmodal = undefined;
    } else if (typeof showmodal !== "undefined" && isProduct === true && eventsCatsArr.indexOf(storeProducts[0].product.category) >= 0) {
      productLink = event.currentTarget.href.split("/").pop();
      showmodal = undefined;
    } else if (typeof showmodal === "undefined" && isProduct === true && eventsCatsArr.indexOf(storeProducts[0].product.category) >= 0) {
      productLink = event.currentTarget.href.split("/").pop();
      showmodal = undefined;
    } else {
      productLink = window.location.href.split("/").pop();
      showmodal = undefined;
    }

    storeProducts.forEach(function (item) {
      // categoryProduct[item.product.urn] = item;
      categoryProduct[item.video.urn] = item;
    });

    // productIndex = categoryProduct[productLink].product;
    productIndex = categoryProduct[productLink].video;
    region = categoryProduct[productLink]._id;






    htmlBody = document.querySelector("body");
  

    if (typeof userLogin !== "undefined" && isVideo) {
      showForm = `<form class="modal-add-to-cart" action="/account/add-to-basket" method="post">
			<input type="hidden" name="resRegion" value="${region}">
			<input type="hidden" name="resProduct" value="${productIndex._id}">
      <input type="hidden" name="isVideo" value="true">
			<button class="btn" type="submit" value="add">Reserve</button>
			</form>`;

      sharebBackButton = `/products/video/${productIndex.category}`;
    } else {
      showForm = "";
      sharebBackButton = `/products/share/${categoryProduct[productLink].name}/${productIndex.category}`;
    }
   

 
      htmlsource = `
      <div class="modal video-modal">
        <div class="modal-content">
          <div class="inner">
            <div class='thumbs'>
              <div class="wrap">
                <div class="product-thubnail">
                    <div class="thumbnail-inner" id="prodthumbs" style="overflow: hidden">
                      <div class="thumb-picture"><img src="${productIndex.thumbnail}" alt=""></div>
                    </div>
                </div>
              </div>
            </div>
            <div class="bigimage">
              <div class="product-gallery" id="prodgal">
                <div data-title="Social.jpg" class="item video">
                <video id="video0" class="slide-video slide-media"  controls muted preload="metadata" type="video/mp4" >
                </video>
                </div>
              </div>
              <div class="imgtitle" id='imgtitle'></div>
            </div>
            <div class="content">
              <div class="content__header">
                <a href="${sharebBackButton}"><span class="close-button">Close</span></a>
              </div>
              <div class="content__body">
                <div class="details">
                  <p>TRN: <span class="meta">${productIndex.urn}</span></p>
                  ${showForm}
                </div>
                <div class="copytext">
                  <div class="confirmation"><span class="icon-checkmark"></span> Copied to clipboard</div>
                  <div class="share-box">https://${link}${productIndex.category}/${productIndex.urn}</div>
                </div>
              </div>
              <div class="content__footer">
                <div class="btn btn--trans_yel share-button">Share Template <span class='shareico'></span></div>
              </div>

            </div>
          </div>
        </div>
      </div>`;
    

    htmlBody.insertAdjacentHTML("beforeend", htmlsource);
    const thumbsSec = document.getElementById("prodthumbs");
    const galSec = document.getElementById("prodgal");

    if (document.querySelector(".contentprodssingle")) {
      const modal = document.querySelector(".modal");
      if (!modal) return;
      modal.addEventListener("click", (e) => {
        if (e.target === modal) {
          modal.classList.remove("show-modal");
          setTimeout(() => {
            modal.remove();
          }, 300);
          const uri = window.location.toString();
          location.href = uri.substring(0, uri.lastIndexOf("/") + 1);
        }
      });
    }

    // stillto add individual thumbs for each item
    // productIndex.mockups.map((thumb, index) => {
      thumbsSec.innerHTML += `
				<div class="thumb-picture"><img src="${productIndex.thumb16x9}" alt=""></div>
			`;

      // if (productIndex.mockupMeta && productIndex.mockupMeta[index] && productIndex.mockupMeta[index]) {
      //   if (productIndex.mockupMeta[index].name.substr(productIndex.mockupMeta[index].name.length - 8) === "17x3.jpg") {
      //     return "";
      //   }
      //   if (productIndex.mockupMeta[index].name.substr(productIndex.mockupMeta[index].name.length - 8) === "17x4.jpg") {
      //     return "";
      //   }
      //   galSec.innerHTML += `
			// 		<div data-title="${productIndex.mockupMeta[index].name}"><img src="${thumb}" alt="Image"/></div>
			// 	`;
      // } else {
        galSec.innerHTML += `
					<div   data-title="16x9.jpg"  class="item video video16x9" >               
           <video id="video1" class="slide-video slide-media"  controls muted preload="metadata" type="video/mp4">
          </video></div>
				`;
      // }
    // });

    var vidSocial = document.getElementById(`video0`);

    if (Array.isArray(productIndex.videoSocial)){
      // get mp4 path for social video
      var mp4Arr = productIndex.videoSocial.filter(function(itm){
        return itm.type == "video/mp4";
      });
      // get webm path for social video
      var webmArr = productIndex.videoSocial.filter(function(itm){
        return itm.type == "video/webm";
      });
      
      if (vidSocial.canPlayType('video/webm') && webmArr.length > 0) vidSocial.src = webmArr[0].path
      else if (vidSocial.canPlayType('video/mp4') && mp4Arr.length > 0) vidSocial.src = mp4Arr[0].path
    }
    // vidSocial.addEventListener("loadeddata", (event) => {
      // console.log('Video state has changed.');
      // console.log(vidSocial.readyState)
            // if (vidSocial.readyState == 4) vidSocial.play();
    // });

    // vidSocial.addEventListener("canplay", (event) => {
      // console.log(vidSocial.readyState)
      // if (vidSocial.readyState == 4) vidSocial.play();

    // });
    vidSocial.addEventListener("canplaythrough", (event) => {
      console.log(vidSocial.readyState)
      if (vidSocial.readyState == 4) vidSocial.play();

    });

    var vid16x9 = document.getElementById(`video1`);

    if (Array.isArray(productIndex.video16x9)){
      // get mp4 path for social video
      var mp4Arr = productIndex.video16x9.filter(function(itm){
        return itm.type == "video/mp4";
      });
      // get webm path for social video
      var webmArr = productIndex.video16x9.filter(function(itm){
        return itm.type == "video/webm";
      });
    
      if (vid16x9.canPlayType('video/webm') && webmArr.length > 0) vid16x9.src = webmArr[0].path
      else if (vid16x9.canPlayType('video/mp4') && mp4Arr.length > 0) vid16x9.src = mp4Arr[0].path
    }
    // vid16x9.addEventListener("loadeddata", (event) => {
    //   // console.log('Video state has changed.');
    //   console.log(vid16x9.readyState)
    //         if (vid16x9.readyState == 4) vid16x9.play();
    // });

    vid16x9.addEventListener("canplaythrough", (event) => {
      console.log(vid16x9.readyState)
      if (vid16x9.readyState == 4) vid16x9.play();

    });

    const imageTitle = document.getElementById("imgtitle");

    function formatTitle(title) {
      if (title) {
        return title.substring(title.lastIndexOf("_") + 1, title.lastIndexOf("."));
      }
      return "";
    }

    setTimeout(function () {
      // Product modal
      if (document.querySelector(".branded")) {
        console.log("content index");
        $(".product-gallery").not(".slick-initialized").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          mobileFirst: true,
          swipeToSlide: true,
          accessibility: true,
          centerPadding: "0",
          focusOnSelect: true,
          centerMode: true,
          rows: 0,
        });

        $(".product-gallery").slickLightbox({
          itemSelector: "img",
          navigateByKeyboard: true,
          src: "src",
        });
      } else {
        console.log("not content index");
        $(".product-gallery").not(".slick-initialized").slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          mobileFirst: true,
          swipeToSlide: true,
          accessibility: true,
          centerPadding: "0",
          focusOnSelect: true,
          centerMode: true,
          rows: 0,
        });



      }


      $(".product-gallery").on("beforeChange", function(event, slick, currentSlide, nextSlide){
    
        // this will run before slide happens to change; so assuming you have 4 slides
        // console.log(currentSlide); // this will be the current slide number 0 - 1 - 2 - 3 
        // console.log(nextSlide); // this will be the upcoming slide number  1 - 2 - 3 - 0
     
        $("#video"+currentSlide)[0].pause();
        //pause current
    
        $("#video"+nextSlide)[0].play();
        //play upcoming
    });

    
      // Thumbnail
      $(".thumbnail-inner").not(".slick-initialized").slick({
        asNavFor: ".product-gallery",
        arrows: false,
        dots: false,
        slidesToShow: 15,
        centerMode: false,
        centerPadding: "10px",
        focusOnSelect: true,
        vertical: true,
      });

      const title = formatTitle($(".product-gallery .slick-active.slick-current").attr("data-title"));
      imageTitle.innerText = title;
    }, 200);

    $(".product-gallery").on("afterChange", function (event, slick, currentSlide, nextSlide) {
      const title = formatTitle($(".product-gallery .slick-active.slick-current").attr("data-title"));
      imageTitle.innerText = title;
    });
  }





  // View Video Product - select mimetype fo video to show

  var viewVidSocial = document.getElementById(`viewVideoSocial`);
if (typeof viewVideoProductIndex !== "undefined") {
    if (Array.isArray(viewVideoProductIndex.videoSocial)){
      // get mp4 path for social video
      var mp4Arr = viewVideoProductIndex.videoSocial.filter(function(itm){
        return itm.type == "video/mp4";
      });
      // get webm path for social video
      var webmArr = viewVideoProductIndex.videoSocial.filter(function(itm){
        return itm.type == "video/webm";
      });
    
      if (viewVidSocial.canPlayType('video/webm') && webmArr.length > 0) viewVidSocial.src = webmArr[0].path
      else if (viewVidSocial.canPlayType('video/mp4') && mp4Arr.length > 0) viewVidSocial.src = mp4Arr[0].path
    }

    var viewVid16x9 = document.getElementById(`viewVideo16x9`);

    if (Array.isArray(viewVideoProductIndex.video16x9)){
      // get mp4 path for social video
      var mp4Arr = viewVideoProductIndex.video16x9.filter(function(itm){
        return itm.type == "video/mp4";
      });
      // get webm path for social video
      var webmArr = viewVideoProductIndex.video16x9.filter(function(itm){
        return itm.type == "video/webm";
      });
    
      if (viewVid16x9.canPlayType('video/webm') && webmArr.length > 0) viewVid16x9.src = webmArr[0].path
      else if (viewVid16x9.canPlayType('video/mp4') && mp4Arr.length > 0) viewVid16x9.src = mp4Arr[0].path
    }
  }

  // Share
  function shareLink() {
    $(".share-button").on("click", function () {
      document.querySelector(".share-box").classList.toggle("show");
      const btntxt = $(this).text();
      const copy = document.querySelector(".share-box").innerHTML;
      if (
        (navigator.vendor != null && navigator.vendor.match(/Apple Computer, Inc./) && navigator.userAgent.match(/iPhone/i)) ||
        navigator.userAgent.match(/iPod/i)
      ) {
        if (document.querySelector(".share-box").classList.contains("show")) {
          $(".confirmation").hide().html("Select and send the following link to your clients.").fadeIn(100);
        } else {
          $(".confirmation").hide().html("Select and send the following link to your clients.").fadeOut(200);
        }
      } else {
        const $temp = $("<input>");
        $("body").append($temp);
        $temp.val(copy).select();
        document.execCommand("copy");
        $temp.remove();
        if (document.querySelector(".share-box").classList.contains("show")) {
          $(".confirmation").hide().html("A <b>Shareable link</b> for your clients has been copied to your clipboard.").fadeIn(100);
        } else {
          $(".confirmation").hide().html("A <b>Shareable link</b> for your clients has been copied to your clipboard.").fadeOut(200);
        }
      }
    });

    $(".share-box").on("click", function () {
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(this);
      selection.removeAllRanges();
      selection.addRange(range);
    });
  }

  $(".btn-delete").on("click", function (event) {
    if (confirm("Are you sure you want to delete") === true) {
    } else {
      event.preventDefault();
    }
  });

  // Show reset form
  $(".resetBtn").on("click", function () {
    document.querySelector(".pwd-reset").classList.toggle("show");
  });

  // Edit
  $(".uploadreplace").on("click", function () {
    $(".replacefile").fadeOut();
    this.setAttribute("required", "true");
  });

  // Cart Timer
  if (typeof cartItem !== "undefined") {
    // load only on pages with the timer
    if (document.getElementById("cartTimer")) {
      // Set the date we're counting down to
      const countDownDate = new Date(cartTimer).getTime();
      // Update the count down every 1 second
      const countdownfunction = setInterval(function () {
        // Get todays date and time
        const now = new Date().getTime();

        // Find the distance between now an the count down date
        const distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        document.getElementById("cartTimer").innerHTML = `${hours} Hours ${minutes} Minutes ${seconds} Seconds`;

        // If the count down is over, write some text
        if (distance < 0) {
          clearInterval(countdownfunction);
          document.querySelector(".flash--warning a").remove();
          document.querySelector(".flash--warning p ").innerHTML = "The item in your cart has expired and will be removed.";
        }
      }, 1000);
    }
  }

  // TRN Filters
  let numLeading;

  // Search tags
  $(".selected").on("change", function () {
    const selectVal = this.options[this.selectedIndex].value;
    const searchTage = document.querySelector(".searchtags");
    const productName = document.querySelector(".productname");

    axios
      .get(`/api/last/${selectVal}`)
      .then(function (response) {
        const part = response.data.split(/([A-Za-z]+)/g);

        // Store both character and numbers
        const characters = part[1]; // Characters
        const digits = part[2]; // numbers

        // Coerce the previous variable as a number and add 1
        let result = +digits + 1;

        // Iinsert leading zeroes with a negative slice
        result = `00000${result}`.slice(-numLeading);

        const newTRN = characters + result;

        productName.value = newTRN;
      })
      .catch(function (err) {
        console.log("No Products exist in this category");
      });

    switch (selectVal) {
      case "education":
        searchTage.innerText =
          "education school primary secondary high independent college university learning homework textbooks uniform tutor teacher mentor pupils students lessons grade subjects maths english science music history geography languages art sport french german spanish performing arts pre-school nursery playtime holidays break time extra-curricular competition lunchtime equipment biology chemistry physics re bus coach transport committee union pta after-school club dance breaks games dt it private public comprehensive curriculum";
        numLeading = 5;
        break;

      case "business":
        searchTage.innerText =
          "business law legal finance accounting broker property insurance assets marketing growth Solicitor asset management employees employer report banking retail charity human resources pr public relations people market research trade revenue broker";
        numLeading = 5;
        break;

      case "leisure":
        searchTage.innerText =
          "leisure wedding bar drinks pub venue restaurant weekend cinema theatre food indian chinese burger italian pizza kebab party bowling cafe coffee movie shopping mall entertainment things to do sport music what’s on quiz dancing club karaoke casino tv outdoor indoor relax what’s on";
        numLeading = 5;
        break;

      case "motors":
        searchTage.innerText =
          "motor car service new used lease finance motor engine lease petrol diesel new registration plate four-door five-door hatchback saloon estate convertible car van motorbike caravan motorhome alloy wheels tyres seat boot storage sat nav bluetooth heating parking sensors safety carseat bike rack roof rack servicing dealer trade showroom ";
        numLeading = 5;
        break;

      case "property":
        searchTage.innerText =
          "property house home sale rent help to buy listings promo detached terrace semi-detached flat apartment bedrooms reception rooms estate agent letting serviced price first-time buyer stamp duty mortgage deposit survey conveyancing moving move viewing auction";
        numLeading = 5;
        break;

      case "recruitment":
        searchTage.innerText =
          "hire hiring recruit recruitment job advertisement applicant role salary company experience results interview work position vacancy opening full-time part-time contract agency temp cv resume recruiting seasonal hourly rate";
        numLeading = 5;
        break;

      case "carpets-flooring":
        searchTage.innerText =
          "carpets flooring laminate tiles tiling underlay heating wood steam clean real wood stone marble ceramic installation quote maintenance pile wool rugs mats warm heating drafts bathroom kitchen stairway fitting ";
        numLeading = 4;
        break;

      case "electrical":
        searchTage.innerText =
          "electrical fridge freezer television tv oven microwave dvd player cd player tumble dryer washing machine computer mac hoover printer telephone mobile smart phone playstation console xbox hairdryer apple tv smart tv coffee machine radio charger usb mp3 speakers soundbar record player vinyl bluetooth camera slr photography editing software hardware sky pc laptop tablet ipad kindle headphones smart watch hair straighteners dishwasher toaster blender";
        numLeading = 4;
        break;

      case "furniture-home":
        searchTage.innerText =
          "home furniture house furnishing lamp home sofa chair table dressing table bed cupboard wardrobe drawers dresser study desk cabinet stool sofa-bed sideboard bedside table shelving storage furnishings rug lighting settee cushion fire fireplace heating mirror lampshade frame picture wallpaper paint decorate decorating improvement doors";
        numLeading = 4;
        break;

      case "glazing":
        searchTage.innerText =
          "windows conservatory extension glazing double triple upvc shiny self-clean tinted mosaic stained glass velux curtains skylight natural light garden room orangery conservatory blinds greenhouse porch patio doors sliding doors heating bi-fold sun room garden lounge";
        numLeading = 4;
        break;

      case "kitchens-bedrooms-bathrooms":
        searchTage.innerText =
          "retail kitchen sink bath bed bedroom bathroom cabinet wardrobe hob cabinet cooking worktop drawers cupboards island mixer tap gas induction drainer storage sleeping bedding children’s bed king size queen size double bed single bed cabin bunk bed lighting pillow duvet fitted design mirror shower wet room toilet wc";
        numLeading = 4;
        break;

      case "services":
        searchTage.innerText =
          "services plumber electrician painter decorate diy house-move removal company cleaner handyman man-and-van estate agent ironing-service inspection survey landscaper gardener tree-surgeon pest control damp proofing driveway block paving jetwash ladder chimney-sweep roofer builder plasterer architect engineer interior designer upholstery seamstress dry cleaners carpet-cleaning drain window-cleaner carpenter dentist doctor optician library ";
        numLeading = 5;
        break;

      case "health-beauty":
        searchTage.innerText =
          "health beauty hair makeup hairdressers barbers shave diet detox fitness gym glasses eye test vision strength lift weight relax spa makeover treatment styling razor cut teeth dental dentist white whitening unwind skin";
        numLeading = 5;
        break;

      case "agriculture":
        searchTage.innerText =
          "agriculture farming fields tractor plow pig cow crops water vegetables tomatoes wheat potatoes greenhouse light bird fertiliser pesticides jcb digger spade digging soil grass plants organic home-made home-grown fruit seeds grow-your-own pick-your-own horses ponies calves chickens show dogs cats sheep eggs cheese milk farm-shop jam marmalade chutney flour straw hay pets corn grain";
        numLeading = 5;
        break;

      case "january-sales":
        searchTage.innerText =
          "seasonal january sales sale bargain discount shopping cheap money online retail bogof high-street designer brands retail park early";
        numLeading = 4;
        break;

      case "easter-spring":
        searchTage.innerText =
          "seasonal egg easter bunny chocolate jesus god religion church fair parade bank holiday presents spring rabbit lamb flowers basket";
        numLeading = 4;
        break;

      case "events":
        searchTage.innerText = "awards ceremony of the year business sports environment nomination entry success gala event";
        numLeading = 5;
        break;

      case "weddings":
        searchTage.innerText =
          "weddings, marriage, engagement, proposal, wedding band, reception, venue, flowers, hair, make-up, styling, table plans, guests, drinks, cocktails, champagne, groom, bridesmaid, mother of the bride, page boy, confetti, church, ceremony, groomsmen, best man, maid of honor, beauty, photography, dancefloor, invitations, ring, vicar, church, catering, vows, tie the knot, get hitched, cold feet, usher, wedding dress, tuxedo, honeymoon, holiday, vintage car, bride, wedding breakfast";
        numLeading = 5;
        break;

      case "apprenticeships":
        searchTage.innerText =
          "apprenticeships apprenticeship tradesmen trade plumber plumbers electrician electricians construction mechanic mechanics engineer engineers IT accounting business human resources hr marketing";
        numLeading = 5;
        break;


      // TODO add events categories search terms
    }
  });

    // Search Video tags
    $(".selected-video").on("change", function () {
      const selectVal = this.options[this.selectedIndex].value;
      const searchTage = document.querySelector(".searchtags");
      const productName = document.querySelector(".productname");

      axios
        .get(`/api/last-video/${selectVal}`)
        .then(function (response) {
          const part = response.data.split(/([A-Za-z]+)/g);
  
          // Store both character and numbers
          const characters = part[1]; // Characters
          const digits = part[2]; // numbers
          // Coerce the previous variable as a number and add 1
          let result = +digits + 1;
          console.log(response.data)
          console.log(result)
  
          // Iinsert leading zeroes with a negative slice
          result = `00000${result}`.slice(-numLeading);
  
          const newTRN = characters + result;
  
          productName.value = newTRN;
        })
        .catch(function (err) {
        console.log("No Products exist in this category");
        });
  
      switch (selectVal) {
        case "education":
          searchTage.innerText =
            "education school primary secondary high independent college university learning homework textbooks uniform tutor teacher mentor pupils students lessons grade subjects maths english science music history geography languages art sport french german spanish performing arts pre-school nursery playtime holidays break time extra-curricular competition lunchtime equipment biology chemistry physics re bus coach transport committee union pta after-school club dance breaks games dt it private public comprehensive curriculum";
          numLeading = 4;
          break;
  
          case "bars-restaurants":
            searchTage.innerText =
              "leisure bar drinks pub venue restaurant weekend food indian chinese burger italian pizza kebab party cafe coffee entertainment things to do sport music what’s on quiz dancing club karaoke indoor relax what’s on";
            numLeading = 4;
            break;
    
            case "retail":
              searchTage.innerText =
                "retail shops supermarkets january sales sale bargain discount shopping cheap money retail bogof high-street designer brands retail park early";
              numLeading = 4;
              break;
             
            case "property-services":
              searchTage.innerText =
                "property house home sale rent help to buy listings promo detached terrace semi-detached flat apartment bedrooms reception rooms estate agent letting serviced price first-time buyer stamp duty mortgage deposit survey conveyancing moving move viewing auction services plumber electrician painter decorate diy house-move removal company cleaner handyman man-and-van estate agent ironing-service inspection survey landscaper gardener tree-surgeon pest control damp proofing driveway block paving jetwash ladder chimney-sweep roofer builder plasterer architect engineer interior designer upholstery seamstress dry cleaners carpet-cleaning drain window-cleaner carpenter dentist doctor optician library ";
              numLeading = 4;
              break;
      
            case "health-fitness":
              searchTage.innerText =
                "health diet detox fitness gym glasses eye test vision strength lift weight relax spa treatment teeth dental dentist white whitening unwind skin exercise";
              numLeading = 4;
              break;

            case "attractions":
              searchTage.innerText =
                "attractions leisure museums sport football concerts gigs fair fairground arcade fun park leisure park rollercoasters wedding bar drinks pub venue restaurant weekend cinema theatre food party bowling cafe coffee movie shopping mall entertainment things to do sport music what’s on quiz dancing club karaoke casino outdoor indoor relax what’s on";
              numLeading = 4;
              break;

            case "b2b":
              searchTage.innerText =
                "b2b business law legal finance accounting broker property insurance assets marketing growth Solicitor asset management employees employer report banking retail charity human resources pr public relations people market research trade revenue broker";
              numLeading = 4;
              break;

            case "e-commerce":
              searchTage.innerText =
                "e-commerce online shopping websites paypal stripe online store shopping cart amazon delivery";
              numLeading = 4;
              break;
      }
    });
});

let clickCheck = 0;

$("#createprodbtn").on("click", () => {
  if (clickCheck === 0) {
    clickCheck = 1;
    $("#finalsubmit").submit();
    $("#createprodbtn").attr("disabled", "disable");
    $("#createprodbtn").val("Uploading...");
    $("#createprodbtn").addClass("disabled");
  }
});

if (document.getElementById("insightdatahubdigitalpage")) {
  digitalReport();
}

// Effectiveness Predictor
if (document.getElementById("predictor")) {
  effectivePredictor();
}

if (
  document.querySelector(".creative-index") ||
  document.querySelector(".events-index") ||
  document.querySelector(".inyourarea-index") ||
  document.querySelector(".facebookarea-index")
) {
  $(".products__item").attr("data-rellax-speed", "-1");
  // $(".products__item:nth-child(5n-1), .products__item:nth-child(5n-3)").addClass("dropped");
  // $(".dropped").attr("data-rellax-speed", "1");

  // const rellax = new Rellax('.products__item');
  // products__item
}

// New insights GDrive Controller Enabler
if (document.getElementById("newInsightPage")) {
  newInsights.newInsights();
}

if (document.getElementById("reports")) {
  dataReports.load();
}

if (document.getElementById("newpubhubpage")) {
  newPub.newPubHub();
}

if (document.getElementById("theinsighthub")) {
  insightHubSearch.init();
}

if (document.getElementById("catInsight")) {
  insightHubSearchCatI.init();
}

if (document.getElementById("insighthubhome")) {
  insightsHome.insightsInit();
}
// Coverage maps
if (document.getElementById("coveragepage")) {
  coverMaps.maps();
}

// Package gradsheet file load
if (document.getElementById("packagegrab")) {
  packagegrabSheet.load();
}

// Edit blog files
if (document.getElementById("editfilesblog")) {
  const inputVals = {};
  console.log("Edit blog files page");

  const cross = document.querySelectorAll(".removeCross");
  for (let i = 0; i < cross.length; i++) {
    cross[i].addEventListener("click", function (e) {
      console.log(this.parentElement.id);
      const val = this.parentElement.id;
      inputVals[i] = this.parentElement.querySelector("input").value;
      this.parentElement.querySelector("input").value = "";
      this.parentElement.querySelector("p").classList.add("removed");
      this.innerHTML = "";
    });
  }
}

if (document.getElementById("analyticsFilter")) {
  document.querySelector("#dateTo").valueAsDate = new Date();
  const grid = document.querySelector("#regsgrid");
  const selector = document.getElementById("toggle");
  selector.addEventListener("change", () => {
    console.log("toggled");
    regsVisibility();
  });

  function regsVisibility() {
    if (selector.value === "false") {
      grid.style.display = "flex";
    } else {
      grid.style.display = "none";
      document.querySelectorAll("#regsgrid input").forEach((elem) => {
        elem.checked = false;
      });
    }
  }
  regsVisibility();
}

// // inyourarea google drive
if (document.getElementById("inyourareaIndex")) {
  inyourareaSearch.load();
}

// // facebook google drive
if (document.getElementById("facebookIndex")) {
  facebookSearch.load();
}

if (document.getElementById("addnewvideo")) {
  contentVideo();
}

if (document.getElementById("contenthubhome")) {
  contentHubHome();
}




// if (document.querySelector('.contentprods')) {
//   const products = document.querySelectorAll('.products__item');
//   console.log(storeProducts);
//   products.forEach(product => {
//     product.addEventListener('click', e => {
//       console.log('clicked');
//       if (history.pushState) {
//         let newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
//         if (newurl.substr(-1) != '/') newurl += '/';

//         newurl += storeProducts[productLink].product.urn;

//         window.history.pushState(
//           {
//             path: newurl,
//           },
//           '',
//           newurl
//         );
//       }
//     });
//   });
// }
